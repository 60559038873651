import styled from 'styled-components'
import vars from '../../styles/vars'

export default styled.h1`
  color: ${vars.colors.accent};
  font-size: 2.5em;
  font-family: ${vars.typography.primary};
  text-transform: lowercase;
  margin: 0;
`
